<script>
import { server } from '@/const';
import { destroyToken, getToken } from '@/shared/services/jwt.service';
import Notify from '@/shared/utils/notify';

const store = {
  state: {
    secondSelection: 'progress',
    title: 'Title 2',
    students: [{
      id: 0,
      name: 'sasffs',
      answers_amount: 6,
      answers_right_amount: 2,
    }],
    depInfo: [],
  },
};
export default {
  name: 'ManagementTestResultsPage',
  data() {
    return {
      store,
      model: {},
    };
  },
  methods: {
    ListStudents() {
      const listStudPath = '/WebOpenaiGroupFileEvaluation.php';
      const data = new FormData();
      data.append('group_id', this.$route.params.parent_id);
      data.append('file_id', this.$route.params.file_id);
      fetch(server + listStudPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      }).then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong authorization token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json.users.length !== 0) {
            store.state.students = json.users;
            store.state.title = json.title;
          }
        });
    },
    getFile() {
      const getDepPath = '/WebOpenaiGetPdfDetails.php';
      const data = new FormData();
      data.append('file_id', parseInt(this.$route.params.file_id, 10));
      fetch(server + getDepPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong authorization token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          } else {
            store.state.title = json.name;
          }
        });
    },
  },
  beforeMount() {
    this.getFile();
    this.ListStudents();
  },
};
</script>
<template>
  <div class="main-select-tests">
    <b-table-simple class="custom-table">
      <b-thead class="first-thead">
        <b-tr>
          <td style="width: 6%;">
            <div class="back" v-on:click="$router.go(-1)">
              <unicon name="angle-left-b" y="-6"
                      width="18" height="18" fill="#828282"/>
            </div>
          </td>
          <td style="width: 28%;">
            <div class="item-details">
              <div class="d-inline-flex">
                <div class="item-image">
                  <unicon height="36" width="36" name="question-circle"
                          fill="#4353FF"></unicon>
                </div>
                <div class="item-info">
                  <div style="width: 100%">
                    <div style="overflow: hidden;height: 24px;
                    text-overflow: ellipsis">
                      {{ store.state.title }}
                    </div>
                    <div class="contains">
                      <div class="ellipse green"></div>
                      <div class="caption-2-reg-12 basic-2">
                        {{ store.state.students.length }}
                        {{ $t('DEPARTAMENTS.STUDENTS') }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
          <td  style="width: 60%;" colspan="2">
          </td>
          <td style="width: 6%;">
            <unicon class="img-more" name="ellipsis-v" fill="#333333"
                    height="16" width="16" style="margin: 16px 0"/>
          </td>
        </b-tr>
      </b-thead>
      <b-thead>
        <b-tr>
          <b-th style="width: 6%;">
            {{ $t("DEPARTAMENTS.NO") }}
          </b-th>
          <b-th style="width: 28%;">
            {{ $t("DEPARTAMENTS.STUDENT") }}
          </b-th>
          <b-th style="width: 44%;">
            {{ $t("FILE_AI.PERCENT_SUCCESSFUL") }}
          </b-th>
          <b-th style="width: 16%;">
            {{ $t("GENERAL.MEMORIZATION") }}
          </b-th>
          <b-th style="width: 6%;">
            <unicon name="setting" fill="#5E5873"/>
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(item,i) in store.state.students" :key="i"
              v-on:click="$router.push(`/management/student/file-student-results/`
              +`${$route.params.parent_id}/${$route.params.file_id}/${item.id}`);">
          <b-td  class="item-no" style="width: 6%;">
            {{ i+1 }}
          </b-td>
          <b-td  style="width: 28%;">
            <div class="user-details">
              <div class="d-inline-flex">
                <div class="item-info d-inline-flex">
                  <div class="user-image">
                    <img :src="item.image ? item.image:
                       '/media/user.jpg'" alt="" />
                  </div>
                  <div style="width: 100%">
                    <div style="overflow: hidden;height: 24px;
                    text-overflow: ellipsis">
                      <unicon height="20" width="20" name="user-circle"
                              fill="#FDAB3D"></unicon>
                      {{item.name}}
                    </div>
                    <div class="contains">
                      <div class="caption-2-reg-12 basic-2">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-td>
          <b-td style="width: 44%;padding-top: 18px">
            <div>
              <div class="basic-1" style="font-weight: 700;font-size: 14px;line-height: 17px;
                  margin-bottom: 4px">
                {{item.evaluation_percent}}%
              </div>
              <b-progress :max="100">
                <b-progress-bar :value="item.evaluation_percent" variant="success">
                </b-progress-bar>
                <b-progress-bar :value="100 - item.evaluation_percent"
                                variant="danger">
                </b-progress-bar>
              </b-progress>
            </div>
          </b-td>
          <b-td style="width: 16%;padding-top: 20px;font-weight: 700;font-size: 14px;
              line-height: 17px;" class="basic-1">
            <div class="procent-container">
              {{item.evaluation_percent}} %
            </div>
          </b-td>
          <b-td style="width: 6%;">
            <unicon name="ellipsis-v" fill="#333333"
                    height="16" width="16" style="margin: 12.25px 0"/>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>
<style>
.main-select-tests  tbody tr:hover,.main-select-tests  tbody tr:hover{
  background-color: #ECEEFF;
  cursor: pointer;
}
.main-select-tests tbody tr:hover td,.main-select-tests tbody tr:hover td{
  background-color: transparent;
}
.procent-container{
  background-color: #4353FF1A;
  border-radius: 6px;
  text-decoration-color: #4353FF;
  padding: 10px;
  width: fit-content;
  margin: auto;
}
</style>
